import { t } from "@lingui/macro";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Image from "next/image";
import Link from "next/link";

import { cloudfrontImageLoader } from "@/lib/util";
import { Banner, BannerCountableEdge, BannerFragment } from "@/saleor/api";

type BannerComponentProps = {
	banner: BannerFragment;
};

const BannerComponent = ({ banner }: BannerComponentProps) => {
	const theme = useTheme();
	const smUp = useMediaQuery(theme.breakpoints.up("sm"));
	return (
		<>
			<Link href={banner.href}>
				<a>
					<div className="flex items-center bg-cover bg-no-repeat aspect-w-7 aspect-h-8 sm:aspect-w-12 sm:aspect-h-5 mb-40 sm:mb-52 md:mb-24">
						<Image
							className="z-0 rounded-b-3xl"
							src={smUp ? banner.desktopUrl : banner.mobileUrl}
							alt={banner.name}
							layout="fill"
							objectFit="cover"
							objectPosition="center"
							priority
							loader={cloudfrontImageLoader}
						/>
						<div className="sm:ml-20 text-gray-50 text-center sm:text-left">
							<p className="text-lg inline-block sm:block"></p>
						</div>
					</div>
				</a>
			</Link>
		</>
	);
};

export default BannerComponent;
