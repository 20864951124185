import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Icon = (props: SvgIconProps) => {
	return (
		<SvgIcon
			width="140"
			height="147"
			viewBox="0 0 140 147"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g filter="url(#filter0_dd_1737_40530)">
				<circle r="28" transform="matrix(-1 0 0 1 70 62)" fill="white" />
				<path
					d="M65.7765 78.002L81.7765 62.002L65.7765 46.002L62.0005 49.778L74.2885 62.002L62.0005 74.226L65.7765 78.002Z"
					fill="#191919"
				/>
			</g>
			<defs>
				<filter
					id="filter0_dd_1737_40530"
					x="0"
					y="0"
					width="140"
					height="147"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="8" />
					<feGaussianBlur stdDeviation="21" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_1737_40530"
					/>
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="22" />
					<feGaussianBlur stdDeviation="17.5" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0"
					/>
					<feBlend
						mode="normal"
						in2="effect1_dropShadow_1737_40530"
						result="effect2_dropShadow_1737_40530"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect2_dropShadow_1737_40530"
						result="shape"
					/>
				</filter>
			</defs>
		</SvgIcon>
	);
};

export default Icon;
