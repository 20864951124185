import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Icon = (props: SvgIconProps) => {
	return (
		<SvgIcon viewBox="0 0 140 147" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g filter="url(#filter0_dd_1637_40297)">
				<circle cx="70" cy="62" r="28" fill="white" />
				<path
					d="M74.223 78.002L58.223 62.002L74.223 46.002L77.999 49.778L65.711 62.002L77.999 74.226L74.223 78.002Z"
					fill="#191919"
				/>
			</g>
			<defs>
				<filter
					id="filter0_dd_1637_40297"
					x="0"
					y="0"
					width="140"
					height="147"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="8" />
					<feGaussianBlur stdDeviation="21" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
					/>
					<feBlend
						mode="normal"
						in2="BackgroundImageFix"
						result="effect1_dropShadow_1637_40297"
					/>
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feOffset dy="22" />
					<feGaussianBlur stdDeviation="17.5" />
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0"
					/>
					<feBlend
						mode="normal"
						in2="effect1_dropShadow_1637_40297"
						result="effect2_dropShadow_1637_40297"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect2_dropShadow_1637_40297"
						result="shape"
					/>
				</filter>
			</defs>
		</SvgIcon>
	);
};

export default Icon;
