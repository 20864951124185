import { t } from "@lingui/macro";
import CircleIcon from "@mui/icons-material/Circle";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { GetStaticPropsContext, InferGetStaticPropsType } from "next";
import Image from "next/image";
import React, { ReactElement, useRef } from "react";
import Slider, { ResponsiveObject } from "react-slick";

import { HomepageBlock, Layout, QuickFilter } from "@/components";
import BannerComponent from "@/components/banner/BannerComponent";
import BaseSeo from "@/components/seo/BaseSeo";
import useChannelContext from "@/components/shared/contexts/ChannelContext";
import { useActiveBannersQuery, useMenuQuery } from "@/saleor/api";

import CarouselForwardIcon from "../icons/CarouselForwardIcon";
import CarouselPrevIcon from "../icons/CarouselPrevIcon";
import styles from "./index.module.sass";

const Home = ({}: InferGetStaticPropsType<typeof getStaticProps>) => {
	const bannerSlider = useRef<Slider>(null);
	const theme = useTheme();
	const { channel } = useChannelContext();
	const smUp = useMediaQuery(theme.breakpoints.up("sm"));
	const mdUp = useMediaQuery(theme.breakpoints.up("md"));
	const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
	const bannerTop = (lgUp && "76%") || (mdUp && "68%") || (smUp && "45%") || "60%";
	const { data: menuData } = useMenuQuery({
		variables: {
			slug: "homepage",
			channel: channel,
		},
		fetchPolicy: "cache-and-network",
	});

	const { data: bannersData } = useActiveBannersQuery({
		variables: {
			first: 10,
		},
		fetchPolicy: "cache-and-network",
	});

	const banners = bannersData?.activeBanners?.edges?.map((edge) => edge.node);

	const renderBanners = () => {
		return banners?.map((banner) => {
			return banner && <BannerComponent banner={banner} />;
		});
	};

	const renderBanner = () => {
		const bannerItems = [
			{
				icon: "/icons/anniversary.svg",
				title: t`Quality Assured`,
				subtitle: t`We ensure product complies with the requirement`,
			},
			{
				icon: "/icons/colorWheel.svg",
				title: t`Best Curated Gifts`,
				subtitle: t`Customise as you please`,
			},
			{
				icon: "/icons/priceTag.svg",
				title: t`Price Match Guaranteed`,
				subtitle: t`We match the lowest price`,
			},
		];

		const renderCustomPaging = (index: number) => {
			return (
				<a>
					<CircleIcon />
				</a>
			);
		};

		const carouselSettings: ResponsiveObject[] = [
			{
				breakpoint: 1280,
				settings: {
					dots: true,
					arrows: true,
				},
			},
			{
				breakpoint: 1024,
				settings: {
					dots: true,
					arrows: true,
				},
			},
			{
				breakpoint: 768,
				settings: {
					dots: false,
					arrows: false,
				},
			},
			{
				breakpoint: 576,
				settings: {
					dots: false,
					arrows: false,
				},
			},
		];

		return (
			<div className="max-w-7xl mx-auto relative">
				{/* <DefaultBanner /> */}
				<Slider
					ref={bannerSlider}
					className={styles.bannerSlider}
					slidesToShow={1}
					slidesToScroll={1}
					rows={1}
					dots={mdUp && true}
					dotsClass="slick-dots"
					lazyLoad="progressive"
					// nextArrow={<ArrowForwardIosIcon color="primary" />}
					// prevArrow={<ArrowBackIosNewIcon />}
					responsive={carouselSettings}
					nextArrow={
						<CarouselForwardIcon
							sx={{ fontSize: 100, opacity: "50%" }}
							color="primary"
							id="banner-next-btn"
						/>
					}
					prevArrow={
						<CarouselPrevIcon
							sx={{ fontSize: 100, opacity: "50%" }}
							color="primary"
							id="banner-prev-btn"
						/>
					}
					customPaging={renderCustomPaging}
					useCSS={false}
					autoplay={true}
					autoplaySpeed={8000}
				>
					{renderBanners()}
				</Slider>
				<div
					className="absolute bg-white rounded-3xl py-8 px-10 left-0 right-0 grid grid-cols-1 mx-8 md:grid-cols-3 gap-4 md:mx-24 filter drop-shadow-sm"
					style={{ top: bannerTop }}
				>
					{bannerItems.map((item) => (
						<div className="flex flex-row items-start" key={item.title}>
							<Image src={item.icon} alt={item.title} width={48} height={48} />
							<div className="flex-1 ml-4">
								<div className="text-brand-green font-semibold">{item.title}</div>
								<div className="text-sm">{item.subtitle}</div>
							</div>
						</div>
					))}
				</div>
			</div>
		);
	};

	return (
		<>
			<BaseSeo />
			<div className="pb-10">
				{/* <main> */}
				{renderBanner()}

				{/* <div className="sm:max-w-7xl mx-auto sm:px-8 pt-16 sm:pt-20">
					<CategoriesComponent />
				</div> */}
				<div className="max-w-7xl mx-auto px-8 lg:px-0 xl:px-8 mt-20">
					<QuickFilter />
				</div>
				<div className="max-w-7xl mx-auto px-8 pt-20">
					{menuData?.menu?.items?.map((m) => {
						if (!!m) return <HomepageBlock key={m?.id} menuItem={m} />;
					})}
				</div>
				{/* </main> */}
			</div>
		</>
	);
};

export default Home;

export const getStaticProps = async (context: GetStaticPropsContext) => {
	return {
		props: {},
		revalidate: 60,
	};
};

Home.getLayout = function getLayout(page: ReactElement) {
	return <Layout>{page}</Layout>;
};
